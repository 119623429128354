.menubuttons {
  min-width: 400px;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 9px;
  border: 2px solid black;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  background-color: orange;
  cursor: pointer;
}
table {
  border: 1px solid #000000;
  border-collapse: collapse;
}
th,
td {
  border: 1px solid #000000;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}
